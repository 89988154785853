import React from "react";
import { FaCheck, FaX } from "react-icons/fa6";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    Heading,
    Text,
} from "@chakra-ui/react";

type CustomAlertDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    message: string;
    buttonLabel: string;
    icon: "success" | "failed"; // Use "success" or "failed" to show different icons and styles
    onConfirm: () => void;
};

const CustomAlertDialog: React.FC<CustomAlertDialogProps> = ({
    isOpen,
    onClose,
    title,
    message,
    buttonLabel,
    icon,
    onConfirm,
}) => {
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay zIndex={999999999}>
                <AlertDialogContent mx="auto" my="auto" maxWidth="450px">
                    <AlertDialogBody>
                        <Box p={4} alignItems="center" textAlign={"center"}>
                            <Flex justifyContent="center" mb={5}>
                                <Box
                                    bg={icon === "success" ? "#11396833" : "#D5110033"}
                                    p={3}
                                    borderRadius={"full"}
                                >
                                    {icon === "success" ? (
                                        <FaCheck size={20} color={"#113968"} />
                                    ) : (
                                        <FaX size={20} color={"#D51100"} />
                                    )}
                                </Box>
                            </Flex>
                            <Heading as="h2" size="lg" pb={5}>
                                {title}
                            </Heading>
                            <Text>{message}</Text>
                        </Box>
                    </AlertDialogBody>

                    <AlertDialogFooter justifyContent={"center"} pb={10}>
                        <Button
                            bg={icon === "success" ? "#113968" : "#D51100"}
                            color={"#fff"}
                            _hover={{ bg: icon === "success" ? "#113968" : "#D51100" }}
                            px={10}
                            py={6}
                            onClick={onConfirm}
                            ml={3}
                        >
                            {buttonLabel}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default CustomAlertDialog;
