import { lazy } from "react";

import {
    CreatePermission,
    EditConfig,
    EditPermission,
    ListDataset,
    ListMenu,
    ListPermission,
    ListRole,
    ListUser,
} from "pages";
import Login from "pages/auth/login";
import Datalake from "pages/datalake";
import Datasets from "pages/datasets";
import DynamicDashboard from "pages/dynamicDashboard";
import ResetPasswordAfterLogin from "pages/ResetPasswordAfterLogin";
import SubDashboard from "pages/subDashboard";

import PrivateLayout from "components/Layout/PrivateLayout/PrivateLayout";
import PrivateSettingRole from 'components/Layout/PrivateLayout/PrivateSettingRole'
import PrivateSettingUsers from 'components/Layout/PrivateLayout/PrivateSettingUsers'

const Dashboard = lazy(() => import("pages/dashboard"));

const routes: Routes[] = [
    {
        path: "/",
        name: "dashboard",
        component: <Dashboard />,
    },
    {
        path: "/dashboard/:menu/:submenu",
        name: "dynamic_dashboard",
        component: <SubDashboard />,
    },
    {
        path: "/:parent_menu/:menu/:source",
        name: "dynamic_menu",
        component: <DynamicDashboard />,
    },
    {
        path: "/datasets",
        name: "datasets_dashboard",
        component: (
            <Datasets />
        ),
    },
    {
        path: "/datalake",
        name: "datalake_dashboard",
        component: (
            <PrivateLayout>
                <Datalake />
            </PrivateLayout>
        ),
    },

    {
        name: "list_user",
        path: "/setting/users",
        component: (
            <PrivateLayout>
                <PrivateSettingUsers>
                    <ListUser />
                </PrivateSettingUsers>
            </PrivateLayout>
        ),
    },
    // {
    //     name: "create_user",
    //     path: "/setting/users/create",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <PrivateCreateUsers>
    //                 <CreateUser />
    //             </PrivateCreateUsers>
    //         </PrivateLayout>
    //     ),
    // },
    // {
    //     name: "edit_user",
    //     path: "/setting/users/edit/:id",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <PrivateEditUsers>
    //                 <EditUser />
    //             </PrivateEditUsers>
    //         </PrivateLayout>
    //     ),
    // },

    // Role Management
    {
        name: "list_role",
        path: "/setting/roles",
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <ListRole />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    // {
    //     name: "create_role",
    //     path: "/setting/roles/create",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <PrivateSettingRole>
    //                 <CreateRole />
    //             </PrivateSettingRole>
    //         </PrivateLayout>
    //     ),
    // },
    // {
    //     name: "edit_role",
    //     path: "/setting/roles/edit/:id",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <PrivateSettingRole>
    //                 <EditRole />
    //             </PrivateSettingRole>
    //         </PrivateLayout>
    //     ),
    // },

    // Menu Managemen
    {
        name: "list_menu",
        path: "/setting/menus",
        component: (
            <PrivateLayout>
                <ListMenu />
            </PrivateLayout>
        ),
    },
    // {
    //     name: "create_menu",
    //     path: "/setting/menus/create",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <PrivateCreateMenus>
    //                 <CreateMenu />
    //             </PrivateCreateMenus>
    //         </PrivateLayout>
    //     ),
    // },
    // {
    //     name: "edit_menu",
    //     path: "/setting/menus/edit/:id",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <PrivateEditMenus>
    //                 <EditMenu />
    //             </PrivateEditMenus>
    //         </PrivateLayout>
    //     ),
    // },

    // Dataset Managemen
    {
        name: "list_dataset",
        path: "/setting/datasets",
        component: (
            <PrivateLayout>
                <ListDataset />
            </PrivateLayout>
        ),
    },
    // {
    //     name: "create_dataset",
    //     path: "/setting/datasets/create",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <CreateDataset />
    //         </PrivateLayout>
    //     ),
    // },
    // {
    //     name: "edit_dataset",
    //     path: "/setting/datasets/edit/:id",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <EditDataset />
    //         </PrivateLayout>
    //     ),
    // },

    // Permission Managemen
    {
        name: "list_permission",
        path: "/setting/permissions",
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <ListPermission />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "create_permission",
        path: "/setting/permissions/create",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <CreatePermission />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "edit_permission",
        path: "/setting/permissions/edit/:id",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <EditPermission />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },

    {
        name: "reset_password",
        path: "/setting/reset-password",
        component: (
            <PrivateLayout>
                <ResetPasswordAfterLogin />
            </PrivateLayout>
        ),
    },

    {
        name: "edit_config",
        path: "/setting/config/edit/:id",
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <EditConfig />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "login",
        path: "/login",
        hide: true,
        component: <Login />,
    },
    // {
    //     name: "sort_menu",
    //     path: "/setting/menus/sort",
    //     component: (
    //         <PrivateLayout>
    //             <PrivateSortMenus>
    //                 <SortMenu />
    //             </PrivateSortMenus>
    //         </PrivateLayout>
    //     ),
    // },

];

export default routes;
