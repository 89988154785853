/* eslint-disable unused-imports/no-unused-vars */
// eslint-disable-next-line
import React, {
    useEffect,
    useState,
    // useCallback 
} from "react";

import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import * as XLSX from 'xlsx';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    CircularProgress,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Link as LinkCakra,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    SimpleGrid,
    Skeleton,
    Spacer,
    Spinner,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useBreakpointValue,
    useDisclosure,
    useToast,
    VStack

} from "@chakra-ui/react";

import {
    useCreateDatalakeMutation,
    useDeleteDatalakeMutation,
    useEditDatalakeMutation,
    useGetAllDatalakeQuery,
    useShowMenuDatalakeQuery,
} from "redux/services/datalake";
import Papa from 'papaparse';


import { useGetMeQuery } from "redux/services/apiService";
import { FaSearch } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { BsFillTrashFill } from "react-icons/bs";
import { HiOutlineCloudUpload } from "react-icons/hi";

const Datalake: React.FC = (): JSX.Element => {
    const toast = useToast()
    const { t } = useTranslation()
    const [deleteItem] = useDeleteDatalakeMutation()
    const [editItem] = useEditDatalakeMutation()
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalEdit, setisOpenModalEdit] = useState(false);
    const handleDelete = (index) => {
        setIsOpenModalDelete(true);
        setIndexDelete(index)
    };

    const handleCloseModal = () => {
        setIsOpenModalDelete(false);
        setisOpenModalEdit(false)
    };
    const [dataArray, setDataArray] = useState([])
    const handleEdit = (index) => {
        setisOpenModalEdit(true)
        setEditIndex(index)
        const dataObject = data?.data[index]
        const excludesKey = ["created_at", "updated_at", "id"]
        const dataArr = []
        const outputFilter = Object.keys(dataObject)
            .reduce((obj, key) => {
                if (!excludesKey.includes(key)) {
                    obj[key] = dataObject[key];
                }
                return obj;
            }, {});
        for (let prop in outputFilter) {
            dataArr.push(outputFilter[prop])
        }
        const output = data?.header_views.map(key => outputFilter[key])
        setDataArray(output.filter(item => item !== undefined))
    }

    // State untuk menyimpan menu yang dipilih
    const [selectedMenu, setSelectedMenu] = useState({
        menu: "",
        sub: "",
        indexMenu: 0,
        indexSub: 0,
        page: 1
    });

    const { data: user } = useGetMeQuery();

    // eslint-disable-next-line unused-imports/no-unused-vars
    let [selectedMenuDatalake, setSelectedMenuDatalake] = useState({
        id_user: user ? user.name : null,
        id_datalake: user ? user.permissions : null,
    });
    useEffect(() => {
        if (user) {
            setSelectedMenuDatalake({
                id_user: user.name,
                id_datalake: user.permissions,
            });
        }
    }, [user]);

    const { data: dataMenu, isFetching: isFetchingMenu } = useShowMenuDatalakeQuery({
        data: selectedMenuDatalake, // Pass `data` here
    });

    // Perbarui selectedMenu ketika dataMenu sudah tersedia
    useEffect(() => {
        if (dataMenu?.data) {
            const firstMenu = dataMenu?.data[0];
            if (firstMenu) {
                setSelectedMenu({
                    menu: firstMenu.slug,
                    sub: firstMenu.submenu[0].name,
                    indexMenu: 0,
                    indexSub: 0,
                    page: 1,
                });
            }
        }
    }, [dataMenu]);

    // Menjalankan query hanya jika selectedMenu.menu dan selectedMenu.sub sudah ada
    const { data, isFetching, isError } = useGetAllDatalakeQuery(
        selectedMenu.menu && selectedMenu.sub ? selectedMenu : skipToken // skipToken digunakan untuk menunda query
    );

    const dataFilter = data?.header_views.filter(item => item !== "created_at" && item !== "updated_at")

    const columnFormated = [
        "tgl_masuk",
        "tgl_awal",
        "tgl_akhir",
        "tgl_ijin",
        "tgl",
        "tanggal_lahir",
        "tgl_penetapan",
        "tgl_jatah",
        "tgl_disetujui",
        "tgl_lahir",
        "tgl_spl",
        "tgl_libur",
        "tgl_mulai",
        "tgl_ijazah",
        "tgl_nota",
        "tgl_sk_cpns",
        "tgl_sk",
        'tgl_msk',
        'tgl_nilai',
        'tgl_selesai',
        'tgl_sttp',
        'tgl_sklain',
        'tgl_berhenti',
        'tgl_pengundangan',
        'tanggal_sertifikat',
        'tanggal',
        'tgl_biu',
        'tgl_sertifikat',
        "tgl_luncur",
        "tgl_luncur_sp",
        "tgl_luncur_sp2"
    ];
    const defaultValue = "";
    const dateValue = "";
    const yearValue = ""
    const timestampValue = ""

    const resultDataSample = [];

    for (let i = 0; i < dataFilter?.length; i++) {
        if (dataFilter[i] === "d_rcv") {
            resultDataSample.push(timestampValue);
        }
        else if (dataFilter[i] === "tahun") {
            resultDataSample.push(yearValue);
        }
        else if (columnFormated.includes(dataFilter[i])) {
            resultDataSample.push(dateValue);
        } else {
            resultDataSample.push(defaultValue);
        }
    }

    const [pageCount, setPageCount] = useState(1)

    const handleMenu = ({ menu, sub, indexMenu, indexSub }) => {
        setSelectedMenu({ menu, sub, indexMenu, indexSub, page: 1 });
    }
    useEffect(() => {
        setPageCount(data?.last_page)
    }, [data])

    const handlePageClick = async (data: any) => {
        setSelectedMenu({ menu: selectedMenu.menu, sub: selectedMenu.sub, indexMenu: selectedMenu.indexMenu, indexSub: selectedMenu.indexSub, page: data.selected + 1 })
    };
    const [createDatalake, { isLoading: isUpdating }] = useCreateDatalakeMutation();
    const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();
    const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // Store uploaded file
    const [isPaused, setIsPaused] = useState(false); // Track if the upload is paused
    const [isUploadComplete, setIsUploadComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dataExcel, setDataExcel] = useState({ "name": "", "jenis": "", data: [] });
    const handleFileUpload = (event) => {
        const file = event.target.files?.[0];
  
        if (file) {
          // Check if the file is either a CSV or Excel
          const allowedTypes = [
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
              "application/vnd.ms-excel", // .xls
              "text/csv", // .csv
          ];
      
          if (!allowedTypes.includes(file.type)) {
              setErrorMessage("Only CSV and Excel files are allowed."); // Set error message
              setUploadedFile(null); // Reset file state if invalid
              return; // Exit the function if file type is invalid
          }
          setUploadedFile(file);
          setIsUploadComplete(false);
          setErrorMessage(""); // Clear any previous errors
          const fakeUploadProgress = setInterval(() => {
            setUploadProgress((prev) => {
              if (isPaused) {
                clearInterval(fakeUploadProgress);
                return prev;
              }
              if (prev >= 100) {
                const reader = new FileReader();
        
                reader.onload = (event) => {
                    try {
                        // setIsFetching(true);  // Show loading state
                        // setIsError(false);     // Reset error state
        
                        const workbook = XLSX.read(event.target.result, { type: 'binary' });
                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];
                        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        let data = [];
                        if (sheetData.length > 0) {
                            // const headers = sheetData[0];
                            const rows = sheetData.slice(1);
                            // setData({ header: headers, data: rows });
                            // Simulate createDatalake function
                            setDataExcel({ "name": selectedMenu.menu, "jenis": selectedMenu.sub, data: rows });
                            // eslint-disable-next-line
                            rows.map((e) => {
                                data.push(e);
                            })
                        }
                    } catch (error) {
                        console.error('Error reading file:', error);
                    } 
                };
        
                reader.readAsBinaryString(file);
                clearInterval(fakeUploadProgress);
                setIsUploadComplete(true); // Mark upload as complete
                return 100;
              }
              return prev + 10; // Increment progress
            });
          }, 200);
        }
    };
    const handleClose = () => {
        setUploadedFile(null);
        setUploadProgress(0);
        setIsUploadComplete(false);
        setIsPaused(false);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const onSubmit = async () => {
        try {
            if (!uploadedFile) {
                setErrorMessage("No file uploaded.");
                return;
            }
            // Simpan data Excel ke API atau backend di sini
            await createDatalake(dataExcel);

            console.log("Data submitted successfully");
            handleClose(); // Reset after successful submission
            setErrorMessage("");
            onCloseCreate();
        } catch (error) {
            console.error("Error submitting data:", error);
            setErrorMessage("Error submitting the file.");
        }
    };

    const handleDownloadTemplate = () => {
        console.log(data, "data");
        
        const dataDownload = { fields: [...data?.header_import.filter(item => item !== "created_at" && item !== "updated_at")], data: resultDataSample }
        const csvData = Papa.unparse(dataDownload, { newline: '\n' });
        console.log(csvData, "csvData")
        const blob = new Blob([csvData], { type: 'text/csv' })
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `template_${selectedMenu.sub}_${selectedMenu.menu}.csv`;
        alink.click();
    }


    const [indexDelete, setIndexDelete] = useState(null)
    const handleDeleteItem = async () => {
        let items = {
            name: selectedMenu.menu,
            jenis: selectedMenu.sub,
            data: data?.data[indexDelete]
        }
        await deleteItem(items);
        toast({
            title: t("Delete Success"),
            isClosable: true,
            status: "success",
            duration: 3000,
        });
        setIsOpenModalDelete(false)
    }
    const [dataEdit, setDataEdit] = useState({})
    columnFormated.forEach(key => {
        if (key in dataEdit) {
            let value = dataEdit[key];
            if (value.includes("undefined")) {
                dataEdit[key] = "";
            } else if (!value.includes('-')) {
                dataEdit[key] = value
            } else {
                let [year, month, day] = value.split("-");
                if (day === undefined) day = "";
                if (month === undefined) month = "";
                if (year === undefined) year = "";
                dataEdit[key] = `${day}/${month}/${year}`.replace(/^\//, '');
            }
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataEdit((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const date = new Date();
    const offset = 7 * 60 * 60 * 1000;
    const dateString = new Date(date.getTime() + offset).toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const [editIndex, setEditIndex] = useState(null)
    const handleEditItem = async () => {
        if (data?.header_views.includes("updated_at")) {
            dataEdit["updated_at"] = dateString
        }
        const dataBefore = data?.data[editIndex]
        let items = {
            name: selectedMenu.menu,
            jenis: selectedMenu.sub,
            data: dataBefore,
            update: dataEdit
        }
        await editItem(items);
        toast({
            title: t("Edit Success"),
            isClosable: true,
            status: "success",
            duration: 3000,
        });
        setisOpenModalEdit(false)
        setDataEdit({})
    }
    // config mobile 
    const isMobile = useBreakpointValue({ base: true, md: false });
    return (
        <>
            <Box
                w={{ base: "100%", md: "98%" }}
                // pl="1.4rem"
                mt="4"
            >
                <LinkCakra
                    color={"blue.600"}
                    fontWeight={"bold"}
                >
                    {t("menu_datalake")}
                </LinkCakra>
                <Flex
                    minWidth='max-content'
                    alignItems='start'
                    gap='2'
                    my="4"
                    direction={isMobile ? "column" : "row"}
                >
                    <Box>
                        <Heading
                            as="h4"
                            size="lg"
                            flex="1" >
                            {t("menu_datalake")}
                        </Heading>
                        <Text
                            fontSize='md'
                        >
                            {t("text_datalake")}
                        </Text>
                    </Box>
                    <Spacer />
                </Flex>
            </Box>
            <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap="10px">
                {/* menubar */}
                <Box w={isMobile ? "full" : "600px"} overflow="auto" backgroundColor="white" style={{ "height": "calc(100vh)" }} position={isMobile ? "relative" : "sticky"} top="0px">
                    {
                        isFetchingMenu ? <Spinner /> : (
                            <>
                                <Accordion className="accordion-datalake" allowToggle>
                                    {
                                        dataMenu.data.map((menu, indexMenu) => (
                                            <AccordionItem className="accordion-item-datalake">
                                                <h2>
                                                    <AccordionButton _expanded={{ bg: '#E8EFF7', color: '#757575' }} p={4}>
                                                        <Box flex='1' textAlign='left'>
                                                            {menu.menu.split("_").join(" ")}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                {
                                                    menu.submenu.map((sub, indexSub) => (
                                                        <AccordionPanel pb={4} p="0px" color={"#757575"} mt={3}>
                                                            <Button p={6}
                                                                colorScheme='gray'
                                                                w="100%"
                                                                justifyContent="flex-start"
                                                                borderRadius="0px"
                                                                onClick={() => {
                                                                    handleMenu({ menu: menu.menu, sub: sub.name, indexMenu, indexSub })
                                                                    const contentElement = document.getElementById("content-datalake");
                                                                    if (contentElement) {
                                                                        contentElement.scrollIntoView({ behavior: 'smooth' });
                                                                    }
                                                                }}>{sub.name.split("_").join(" ")}</Button>
                                                        </AccordionPanel>
                                                    ))
                                                }
                                            </AccordionItem>
                                        ))
                                    }
                                </Accordion>
                            </>
                        )
                    }
                </Box>
                {/* menubar */}
                <Box w="100%" px={isMobile ? 0 : 5} overflow="auto" id="content-datalake">
                    <Flex
                        mb={isMobile ? 4 : 4}
                        direction={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignContent={"center"}
                        align={isMobile ? "start" : "center"}
                        w="full" border={"1px solid #E2E8F0"}
                        borderRadius="md"
                        p={2}
                    >
                        <Box mt="10px">
                            <Text mb="0px" fontSize="4xl" textTransform="capitalize" fontWeight="bold">{selectedMenu.sub.split("_").join(" ")}</Text>
                            <Text>{selectedMenu.menu}</Text>
                        </Box>

                        <Box>
                            {
                                isUpdating && <CircularProgress isIndeterminate color='#0792D2' />
                            }
                            {/* <Input
                                type="file"
                                ref={inputRef}
                                display="none"
                                onChange={(e) => { handleImportFile(e) }}
                            /> */}
                            <Button
                                leftIcon={<Icon as={FiDownload} />}
                                colorScheme="blue"
                                size="lg"
                                borderRadius="md"
                                boxShadow="sm"
                                onClick={onOpenCreate}
                            >
                                Import Data
                            </Button>

                        </Box>
                    </Flex>
                    <Box display="flex" justifyContent="space-between" mb="20px" bgColor={"#E9EBEF"} p={4}>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <FaSearch size={16} color='#676B7B' />
                            </InputLeftElement>
                            <Input
                                bg={"white"}
                                type='text'
                                placeholder="Cari Datalake"
                                pl="2.5rem"
                            />
                        </InputGroup>

                    </Box>
                    <Box backgroundColor="white" w="100%" p="20px" borderRadius="5px" border={"1px solid #E2E8F0"}>
                        {
                            isFetching ?
                                <Stack>
                                    <Skeleton height='20px' />
                                    <Skeleton height='20px' />
                                    <Skeleton height='20px' />
                                </Stack>
                                :
                                <TableContainer>
                                    {isError ? <Text>Data is not found</Text> : (
                                        <Table variant='simple'>

                                            <Thead>
                                                <Tr fontWeight="bold">
                                                    {
                                                        data?.header_views.map((e) => (
                                                            <Th>{e}</Th>
                                                        ))
                                                    }
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    data?.data?.map((each, index) => {
                                                        return (
                                                            <Tr>
                                                                {
                                                                    data?.header_views.map((header) => {
                                                                        return (
                                                                            <Td>{each[header]}</Td>
                                                                        )
                                                                    })
                                                                }
                                                                <Menu>
                                                                    <MenuButton as={IconButton} icon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-vertical">
                                                                            <circle cx="12" cy="12" r="1"></circle>
                                                                            <circle cx="12" cy="5" r="1"></circle>
                                                                            <circle cx="12" cy="19" r="1"></circle>
                                                                        </svg>
                                                                    } variant="ghost" />
                                                                    <MenuList>
                                                                        <MenuItem onClick={() => handleEdit(index)}>Edit</MenuItem>
                                                                        <MenuItem onClick={() => handleDelete(index)}>Hapus</MenuItem>
                                                                    </MenuList>
                                                                </Menu>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    )}
                                </TableContainer>
                        }
                    </Box>
                    {data?.last_page ? <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center mt-4"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                        : null}
                </Box>
                <Modal isOpen={isOpenModalDelete} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Peringatan!</ModalHeader>
                        <ModalBody>Apakah Anda yakin ingin menghapus data ini?</ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" mr={3} onClick={handleCloseModal}>
                                Tidak
                            </Button>
                            <Button colorScheme="green" onClick={handleDeleteItem}>
                                Ya
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={isOpenModalEdit} onClose={handleCloseModal} size="4xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Data</ModalHeader>
                        <ModalBody>
                            <Table>
                                <Tbody>
                                    {data?.header_views.filter(item => item !== "created_at" && item !== "updated_at" && item.toLowerCase() !== "id").map((key, index) => {
                                        return (
                                            <Tr>
                                                <Td>{key}</Td>
                                                <Td>
                                                    <Input
                                                        name={key}
                                                        defaultValue={dataArray[index]}
                                                        onChange={handleChange}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleEditItem}>
                                Simpan
                            </Button>
                            <Button variant="ghost" onClick={handleCloseModal}>
                                Batal
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
            <Drawer 
                isOpen={isOpenCreate} 
                onClose={onCloseCreate}
                size="xl"
                placement="right"
            >
                <DrawerOverlay />
                <DrawerContent mt={isMobile ? 20 : 0}>
                <DrawerCloseButton mt={isMobile ? 40 : 5} mx={6} />
                <DrawerHeader mt={isMobile ? 40 : 40} mx={isMobile ? 0 : 10}>Import Data</DrawerHeader>
                <DrawerBody>
                    {/* RoleForm to be filled */}
                    <Box flex="1" pt={5} px={10} bg="white" >
                    <Stack spacing={4} align="end">
                        <FormControl mb={7} isInvalid={!!errorMessage}>
                            <FormLabel fontWeight={"bold"}>Source Datalake</FormLabel>
                            <VStack direction="row" spacing={4}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    border="2px dashed"
                                    borderColor="gray.300"
                                    borderRadius="md"
                                    p={6}
                                    width="100%"
                                    textAlign="center"
                                    cursor="pointer"
                                    as="label"
                                    htmlFor="file-upload"
                                    onChange={handleFileUpload}
                                
                                >
                                    <Input
                                        type="file"
                                        id="file-upload"
                                        display="none"
                                        // {...register("source")}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                    <VStack>
                                        <Icon as={HiOutlineCloudUpload} w={8} h={8} color="#2B4CA0" />
                                        <Text fontSize="sm">
                                            Drag your file(s) or <Text as="span" textDecoration="underline" fontWeight={"bold"} color="#2B4CA0">browse</Text>
                                        </Text>
                                        <Text fontSize="xs" color="gray.500">
                                            Max 10 MB files are allowed
                                        </Text>
                                    </VStack>
                                </Box>
                            </VStack>
                            {errorMessage && ( // Conditionally render error message
                                <Text color="red.500" fontSize="sm" mt={2}>
                                {errorMessage}
                                </Text>
                            )}
                            {errorMessage ? (
                                <FormErrorMessage>
                                    {errorMessage}
                                </FormErrorMessage>
                            ) : (
                                <FormHelperText>
                                    Only support .csv and .xlsx files
                                </FormHelperText>
                            )}
                            {/* Upload Progress */}
                            {uploadProgress > 0 && uploadProgress < 100 && (
                                <Box my={4}>
                                    <Flex justifyContent={"space-between"}>
                                        <Box width={"100%"}>
                                            <Text fontSize="sm" fontWeight={"bold"}>Uploading...</Text>
                                            <Progress width={"90%"} value={uploadProgress} size="xs" colorScheme="blue" />
                                            <Text fontSize="sm">{`${uploadProgress}%`}</Text>
                                        </Box>
                                        <SimpleGrid columns={2} spacing={2}>
                                            
                                            <Button 
                                                onClick={handleClose} 
                                                colorScheme="red" 
                                                size="sm">
                                                <BsFillTrashFill />
                                            </Button>
                                        </SimpleGrid>
                                    
                                    </Flex>
                                </Box>
                            )}

                            {/* Show Uploaded File */}
                            {isUploadComplete && uploadedFile && (
                                <Box my={4} border="1px solid" borderColor="gray.300" borderRadius="md" p={2}>
                                    <Flex justifyContent={"space-between"}>
                                        <Box>
                                            <Text fontSize="sm" fontWeight={"bold"}>Upload Complete! Here is your file:</Text>
                                            {/* Display Image if the file is an image */}
                                            {uploadedFile.type.startsWith("image/") ? (
                                                <Image src={URL.createObjectURL(uploadedFile)} alt="Uploaded File" />
                                            ) : (
                                                <LinkCakra colorScheme={"blue"} href={URL.createObjectURL(uploadedFile)} target="_blank" rel="noopener noreferrer">
                                                    {uploadedFile.name}
                                                </LinkCakra>
                                            )}
                                        </Box>
                                        {/* Close Button after upload completion */}
                                        <Button   
                                            onClick={handleClose} 
                                            colorScheme="red" 
                                            size="sm">
                                            <BsFillTrashFill />
                                        </Button>
                                    </Flex>
                                </Box>
                            )}
                        
                        </FormControl>
                        <VStack mt={4} width="100%">
                            <Button 
                                bg="#2B4CA0"
                                color={"#fff"}
                                _hover={{ bg: "#203D86" }}
                                py={8} 
                                w="full"
                                onClick={onSubmit}
                            >
                                Upload
                            </Button>
                            <Text fontSize="sm" color={"gray.500"}>Ensure your file is in the correct format? <LinkCakra onClick={() => { handleDownloadTemplate() }}  fontWeight={"bold"} color={"#2B4CA0"} colorScheme={"blue"}>Download Template</LinkCakra></Text> 
                        </VStack>
                    </Stack>

                    </Box>
                </DrawerBody>

            </DrawerContent>
            </Drawer>
        </>
    )
};

export default Datalake;
