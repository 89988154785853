import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "redux/baseQuery";
import { removeCreadential, setCredential } from "redux/slices/user";

import { User, UserCredential } from "./types";

export const apiService = createApi({
  reducerPath: "apiService",
  baseQuery: baseQuery,
  tagTypes: ["menu", "user", "role", "permission", "cctv", "config", "dataset", "datalake", "auth"],
  endpoints: (build) => ({
    getMe: build.query<User, void>({
      query: () => ({ url: "/users/me" }),
      providesTags: () => [{ type: "user", id: "me" }],
    }),
    login: build.mutation<any, UserCredential>({
      query: ({ email, password }) => ({
        url: "../../../api/login",
        method: "post",
        data: { email, password },
      }),
      invalidatesTags: () => [{ type: "user", id: "me" }],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          const { data: loggedInUser } = await dispatch(
            apiService.endpoints.getMe.initiate()
          );
          dispatch(setCredential(loggedInUser));
        } catch (error) {
        }
      },
    }),
    logout: build.mutation<any, void>({
      query: () => ({
        url: "../../../logout",
        method: "post",
      }),
      invalidatesTags: () => [{ type: "user", id: "me" }],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        await queryFulfilled;
        dispatch(removeCreadential());
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useGetMeQuery, } = apiService;

