import { apiService } from "redux/services/apiService";

const TYPE = "auth";
const userApi = apiService.injectEndpoints({
    endpoints: (build) => ({
        sendEmail: build.mutation({
            query: (data) => ({ url: "/forgot-password", method: "post", data }),
            invalidatesTags: [{ type: TYPE, id: "list" }],
        }),
        resetPassword: build.mutation({
            query: (data) => ({ url: "/reset-password", method: "post", data }),
            invalidatesTags: [{ type: TYPE, id: "list" }],
        }),
        resetPasswordAfterLogin: build.mutation({
            query: (data) => ({ url: "/reset-password/reset", method: "post", data }),
            invalidatesTags: [{ type: TYPE, id: "list" }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useSendEmailMutation,
    useResetPasswordMutation,
    useResetPasswordAfterLoginMutation,
} = userApi;
