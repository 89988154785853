// eslint-disable-next-line
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";

import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useToast,
} from '@chakra-ui/react';
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";

import { useResetPasswordAfterLoginMutation } from 'redux/services/auth';
import {
    selectUserEmail,
    selectUserName,
} from "redux/slices/user";
import { useAppSelector } from "redux/store";

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlertDialog from "components/CustomDialog/CustomAlertDialog";

// Schema for form validation using Yup
const schema = yup.object().shape({
    oldPassword: yup
        .string()
        .required('Old password is required'),
    newPassword: yup
        .string()
        .min(6, 'New password must be at least 6 characters')
        .required('New password is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

const ResetPasswordAfterLogin = () => {
    const { register, handleSubmit, formState: { errors, isSubmitting }, setError, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const toast = useToast();
    const [isModalCancelOpen, setIsModalCancelOpen] = useState(false); // Modal state
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

    const onSubmit = async (data) => {
        try {
            await resetPasswordAfterLogin({
                email: selectEmail,
                oldPassword: data.oldPassword,
                newPassword: data.newPassword,
            }).unwrap();
            setIsModalOpen(true)
        } catch (error) {
            if (error?.status === 400) {
                setError('oldPassword', {
                    type: 'manual',
                    message: t("wrong_old_password")
                });
            } else {
                toast({
                    title: `Error: ${error.data?.error || 'Something went wrong'}`,
                    isClosable: true,
                    status: "error",
                    duration: 3000,
                });
            }
            setIsModalCancelOpen(true)
        }

    };
    const { t } = useTranslation();

    const selectEmail = useAppSelector(selectUserEmail);
    const selectName = useAppSelector(selectUserName);

    const [resetPasswordAfterLogin] = useResetPasswordAfterLoginMutation();

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleClickNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleClickConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const onConfirmCancel = () => {
        setIsModalCancelOpen(false);
    };

    const onConfirm = () => {
        setIsModalOpen(false); // Close modal
        reset(); // Reset the form fields
    };

    return (
        <>
            <Box color={"#2B4CA0"} fontSize="14px">{t("reset_password")}</Box>
            <Box px={"20px"} py="10px">
                <Text fontWeight={"bold"} borderBottom="solid 2px black" py="10px">{t('reset_password')}</Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl mb="4">
                        <FormLabel>{t("name")}</FormLabel>
                        <Input type="text" disabled value={t(selectName)} bg="lightgrey" />
                    </FormControl>
                    <FormControl mb="4">
                        <FormLabel>{t("email")}</FormLabel>
                        <Input type="email" disabled value={t(selectEmail)} bg="lightgrey" />
                    </FormControl>

                    <FormControl isInvalid={errors.oldPassword} mb="4">
                        <FormLabel>{t("oldPassword")}<span style={{ color: "red", fontWeight: "bold" }}>*</span></FormLabel>
                        <InputGroup>
                            <Input
                                {...register('oldPassword')}
                                placeholder="*********"
                                type={showOldPassword ? "text" : "password"}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={handleClickOldPassword} variant="ghost">
                                    {showOldPassword ? <RxEyeClosed /> : <RxEyeOpen />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.oldPassword?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.newPassword} mb="4">
                        <FormLabel>{t("newPassword")}<span style={{ color: "red", fontWeight: "bold" }}>*</span></FormLabel>
                        <InputGroup>
                            <Input
                                {...register('newPassword')}
                                placeholder="*********"
                                type={showNewPassword ? "text" : "password"}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={handleClickNewPassword} variant="ghost">
                                    {showNewPassword ? <RxEyeClosed /> : <RxEyeOpen />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>{errors.newPassword?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.confirmPassword} mb="4">
                        <FormLabel>{t("confirmPassword")}<span style={{ color: "red", fontWeight: "bold" }}>*</span></FormLabel>
                        <InputGroup>
                            <Input
                                {...register('confirmPassword')}
                                placeholder="*********"
                                type={showConfirmPassword ? "text" : "password"}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={handleClickConfirmPassword} variant="ghost">
                                    {showConfirmPassword ? <RxEyeClosed /> : <RxEyeOpen />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
                    </FormControl>

                    <Button
                        isLoading={isSubmitting}
                        background={"#2B4CA0"}
                        type="submit"
                        width="full"
                        color="white"
                        mt="4"
                        _hover={{ background: "blue" }}>
                        {t("button_save")}
                    </Button>
                </form>
            </Box>
            <CustomAlertDialog
                isOpen={isModalCancelOpen}
                onClose={() => setIsModalCancelOpen(false)}
                title={t("failed")}
                message={t("wrong_old_password")}
                buttonLabel={t("button_back")}
                icon="failed"
                onConfirm={onConfirmCancel}
            />

            <CustomAlertDialog
                isOpen={isModalOpen}
                onClose={onConfirm} // Reset form on close
                title={t("success")}
                message={t('success_reset_password_after_login')}
                buttonLabel={t("next")}
                icon="success"
                onConfirm={onConfirm}
            />

        </>
    );
};

export default ResetPasswordAfterLogin;
