import React from "react";

type Props = {};
const PrivateSettingUsers: React.FC<Props> = ({ children }): JSX.Element => {
    

    return <>{children}</>;
};

export default PrivateSettingUsers;
