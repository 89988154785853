import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";

// const toast = createStandaloneToast({ theme });

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.meta.arg.endpointName !== "getMe") {
        // toast({
        //   description: action.payload.data.message,
        //   status: "error",
        //   duration: 9000,
        //   isClosable: true,
        // });
      }
    }

    return next(action);
  };
