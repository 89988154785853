import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Box, ChakraProvider, Spinner } from "@chakra-ui/react";

import { useGetConfigsQuery } from "redux/services/config";
import { selectBrandColor } from "redux/slices/color";
import store, { useAppSelector } from "redux/store";

import theme from "definitions/chakra/theme";
import routes from "routes";

import Layout from "components/Layout/Layout";
import ModalComponent from "components/Modal/Modal";
import { generateColorScale } from "utils/helpers";

import "./i18n";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "styles/global.css";

const LoginPage = lazy(() => import("pages/auth/login"));
const ForgetPassword = lazy(() => import("pages/auth/forgetPassword"));
const ResetPassword = lazy(() => import("pages/auth/resetPassword"));

const AppRoute = () => {
    const { data: config } = useGetConfigsQuery();
    let brandColor = useAppSelector(selectBrandColor);
    brandColor = config?.data[0]?.color;
    const brandColorScale = generateColorScale(brandColor);

    return (
        <ChakraProvider
            theme={{
                ...theme,
                colors: {
                    ...theme.colors,
                    brand: brandColorScale,
                },
            }}
        >
            <ModalComponent />
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/auth/login"
                        element={
                            <Suspense fallback={
                                <>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'
                                />
                                </>
                            }>
                                <LoginPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/forget-password"
                        element={
                            <Suspense fallback={
                                <>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'
                                />
                                </>
                            }>
                                <ForgetPassword />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/reset-password/:token/:email"
                        element={
                            <Suspense fallback={
                                <>
                                <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                                />
                                </>
                            }>
                                <ResetPassword />
                            </Suspense>
                        }
                    />

                    <Route element={<Layout />}>
                        {routes.map((route) => (
                            <Route
                                key={route.name}
                                path={route.path}
                                element={
                                    <Suspense fallback={
                                        <>

                                            <Box mt="4" textAlign="center">
                                                <Spinner
                                                    thickness='4px'
                                                    speed='0.65s'
                                                    emptyColor='gray.200'
                                                    color='blue.500'
                                                    size='xl'
                                                />
                                            </Box>
                                        </>
                                    }>
                                        {route.component}
                                    </Suspense>
                                }
                            />
                        ))}
                    </Route>
                </Routes>
            </BrowserRouter>
        </ChakraProvider>
    );
};

function App(): JSX.Element {
    return (
        <Provider store={store}>
            <AppRoute />
        </Provider>
    );
}

export default App;
