import {
    Flex,
    Image,
    Stack,
    useBreakpointValue,
} from "@chakra-ui/react";


const SideBarLogin = () => {

    // Detect breakpoint to switch between SideBar and TopBar
    const isMobile = useBreakpointValue({ base: true, md: false });
    
    if (isMobile) {
        // Return TopBar for mobile view
        return (
            <Flex
                bg={"#1E3364"}
                w="100%"
                padding={"10px"}
                justifyContent="space-between"
                alignItems="center"
            >
                <Image src="/assets/logo SDPPI.png" alt="Logo SDPPI" loading="lazy" />
            </Flex>
        );
    }
    return (
        <Stack bg={"#1E3364"} w={"30%"} padding={"20px"} h="100%">
            <Stack align={"center"} h="100%">
                <Image src="/assets/logo SDPPI.png" alt="Logo SDPPI" loading="lazy" />
                <Stack alignItems="center" justifyContent={"center"} h="100%">
                    <Image src="/assets/globe_login.png" alt="Globe" loading="lazy" w={"95%"} />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default SideBarLogin
