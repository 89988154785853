import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFolder, FaSearch } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, Image, Input, InputGroup, InputLeftElement, Link as LinkCakra, Spacer, Tag, Text, useBreakpointValue } from "@chakra-ui/react";

import { useGetDatasetWithParentQuery } from "redux/services/dataset";

import { Skeleton } from "antd";
import axios from "axios";

import { getDateMonthYear } from 'utils/constants';

const DatasetItem = ({ d, list, isMobile, downloadFile, index }) => {
    const isCSV = list?.file.split('.')[1].toLowerCase() === "csv";
    const fileIcon = isCSV ? "/assets/icon_csv2.svg" : "/assets/icon_xls.svg";
    const fileExtension = isCSV ? ".csv" : ".xls";
    const isLastItem = index === d.length - 1; // Deteksi item terakhir

    return (
        <Flex
            className={`accordion_item_child_content ${isLastItem ? "accordion_item_child_last" : "accordion_item_child"}`}
            minWidth='max-content'
            alignItems='center' gap='2' mb={2} p={2}
            border="1px solid #E6E6E6"
            justifyContent={"space-between"}
            key={`dataset-${d.id}-${list.id}`}
        >
            <Flex p="2" gap="5px" flexDir="column">
                <Text fontWeight="bold" fontSize={isMobile ? "xs" : "md"} m={0}>{list.name}</Text>
                <Text fontWeight="light" fontSize={isMobile ? "xs" : "md"} m={0}>{list.desc ?? "-"}</Text>
                <Flex alignItems="center" w="full" onClick={() => downloadFile(list)} cursor="pointer" gap="10px">
                    <Image src={fileIcon} alt={`${isCSV ? "CSV" : "XLS"} Icon`} width={isMobile ? "25px" : "50px"} />
                    <Text m="0" fontSize="13px" color="#222222" fontWeight="bold">
                        <span style={{ textTransform: "capitalize" }}>{list.name}</span>{fileExtension}
                    </Text>
                </Flex>
                <Text mb="0px" fontSize={isMobile ? "xs" : "md"}>{getDateMonthYear(list.created_at)}</Text>
            </Flex>
            <Box onClick={() => downloadFile(list)} cursor="pointer">
                <Image src="/assets/icon_download.svg" alt="Download Icon" width={isMobile ? "25px" : "50px"} />
            </Box>
        </Flex>
    );
};

const DatasetList = ({ datasets, isMobile, downloadFile }) => {
    return datasets.map((list, index) => (
        <Box className={`accordion_panel_content accordion_panel_content_${index}`}
            key={`dataset-${list.id}`}>
            <DatasetItem d={datasets} key={list.id} list={list} isMobile={isMobile} downloadFile={downloadFile} index={index} />
        </Box>
    ));
};

const AccordionPanelContent = ({ dataset, isMobile, downloadFile }) => {
    return (
        <AccordionPanel pb={4}>
            <DatasetList datasets={dataset.list_dataset} isMobile={isMobile} downloadFile={downloadFile} />
        </AccordionPanel>
    );
};

const Datasets = () => {
    const { data: datasets = [] } = useGetDatasetWithParentQuery("");
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const [filteredMenus, setFilteredMenus] = useState([]);
    const isMobile = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        setFilteredMenus(datasets.data || []);
    }, [datasets.data]);

    const handleSearch = () => {
        const filteredData = datasets.data.filter((dataset) =>
            dataset.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredMenus(filteredData);
    };

    const downloadFile = (param) => {
        axios({
            url: `${process.env.REACT_APP_API_URL}/api/v1/download/${param.id}`,
            method: "GET",
            responseType: "blob",
        })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", param.file);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error("Download failed", error);
            });
    };

    return (
        <Box w={{ base: "100%", md: "98%" }} mt="4">
            <LinkCakra color={"blue.600"} fontWeight={"bold"}>
                {t("detail_datasets")}
            </LinkCakra>
            <Flex minWidth="max-content" alignItems="start" gap="2" my="4" direction={isMobile ? "column" : "row"}>
                <Box>
                    <Heading as="h4" size="lg">{t("list_dataset")}</Heading>
                    <Text fontSize="md">{t("text_dataset")}</Text>
                </Box>
                <Spacer />
            </Flex>
            <Flex bg={"gray.100"} p={4} rounded="md" mb={5} direction={isMobile ? "column" : "row"}>
                <Box width={isMobile ? "full" : "30%"} mb={isMobile ? 5 : 0}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <FaSearch size={16} color="#676B7B" />
                        </InputLeftElement>
                        <Input
                            bg="white"
                            type="text"
                            value={searchText}
                            placeholder={t("search_dataset")}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => { if (e.key === "Enter") handleSearch(); }}
                            pl="2.5rem"
                        />
                    </InputGroup>
                </Box>
                <Spacer />
            </Flex>
            {!filteredMenus && <Skeleton />}
            <Accordion allowToggle>
                {filteredMenus?.map((d) => (
                    d.list_dataset?.length > 0 && (
                        <AccordionItem key={`dataset-${d.id}`} className="accordion-item">
                            <AccordionButton
                                key={d.id}
                                className="accordion-button"
                                p={3}
                            >
                                <Flex w="full" alignItems="center" justifyContent="space-between" gap="2">
                                    <Box as="span" flex="1" textAlign="left">
                                        <Flex gap={2}>
                                            {d.icon ? (
                                                <Image src={`${process.env.REACT_APP_API_URL}/ico/${d.icon}`} height="25px" width="25px" alt="ico" />
                                            ) : (
                                                <IconContext.Provider value={{ className: "aktif-default-color" }}>
                                                    <FaFolder color="#343330" fontSize="24px" />
                                                </IconContext.Provider>
                                            )}
                                            <Text fontWeight="bold" fontSize={isMobile ? "xs" : "md"} m={0}>{d.name}</Text>
                                        </Flex>
                                    </Box>
                                    <Spacer />
                                    <Box mr={3}>
                                        <Tag color={d.status === "1" ? "teal" : "red"}>
                                            {d.status === "1" ? t("active") : t("not_active")}
                                        </Tag>
                                    </Box>
                                </Flex>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanelContent dataset={d} isMobile={isMobile} downloadFile={downloadFile} />
                        </AccordionItem>
                    )
                ))}
            </Accordion>
        </Box>
    );
};

export default Datasets;
